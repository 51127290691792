import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import ArticleCard from "./ArticleCard"

const ArticlesGrid = ({excludeSlug = null}) => {
  const { allStrapiArticle } = useStaticQuery(graphql`query {
    allStrapiArticle (
      sort: {
        fields: [featured, sort],
        order: [DESC, ASC]
      }
    ) {
      nodes {
        ...ArticleCard
      }
    }
  }
`)  

  return (
    <div className="flex wrap center gap-large">
      {allStrapiArticle.nodes.filter(articleFilter => articleFilter.slug !== excludeSlug).map((article) => (
        <ArticleCard article={article} />
      ))}
    </div>
  )
}

export default ArticlesGrid;
