import React from "react"
import { Link, graphql } from "gatsby"
import {api_url} from '../../constants/index';
import bottomWave from '../../assets/images/bottom-wave.svg';

const ArticleCard = ({ article }) => {
  return (
    <Link
      to={`/article/${article.slug}`}
      className="flex column start items-center w-25 article-card"
    >
      {/* <div className="flex items-end center" style={{minHeight: "250px"}}>
        {
          article?.cover?.url !== undefined && <img src={`${api_url}${article.cover.url}`} alt="" style={{maxWidth: "250px", maxHeight: "250px"}} />
        }
      </div> */}
      <img className="mt-5" src={bottomWave} style={{maxWidth: "250px"}} alt="" />
      <div className="flex column mt-3">
        <h4 className="text-center">{article.title}</h4>
        <p className="small grey-light bold text-center mt-1">
          {article.author.name}
        </p>
      </div>
    </Link>
  )
}

export const query = graphql`
  fragment ArticleCard on STRAPI_ARTICLE {
    id
    slug
    title
    cover {
      url
    }
    author {
      name
    }
    featured
    sort
  }
`

export default ArticleCard
