import Seo from "../components/Seo";
import React from "react";
import Layout from "../components/Layout";
import waveCalm from "../assets/icons/icon-wavy-calm.svg";
import diveDeeper from "../assets/images/monsters6-dive.png";
import monstersDivorce from "../assets/images/monsters-divorce.svg";
import ArticlesGrid from "../components/articles/ArticleGrid";
import VimeoPlayer from "../components/vimeoplayer/VimeoPlayer";
import bottomWave from "../assets/images/bottom-wave.svg";

const DiveDeeper = () => {
  return (
    <Layout>
      <Seo seo={{ metaTitle: "Dive Deeper" }} />
      <main>
        <section id="safe-harbor-introduction">
          <div className="small container">
            <div className="wrap between flex items-center">
              <div className="column flex w-60">
                <img className="mb-4 w-60" src={waveCalm} alt="" />
                <h1 className="grey">Dive Deeper</h1>
              </div>
              {/* <img className="mb-4" src={diveDeeper} alt="" style={{width:"420px"}} /> */}
            </div>
          </div>
        </section>
        <section>
          <div className="column mb-5 flex items-center">
            <h1 className="mb-3">A Safe Harbor Video Example</h1>
            <img style={{ maxWidth: "300px" }} src={bottomWave} alt="" />
          </div>
          <VimeoPlayer videoId="891532024" />
        </section>
        <section>
          <div className="container">
            <ArticlesGrid />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default DiveDeeper;
